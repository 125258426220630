<template>
  <div>
    <CRow>
      <CCol lg="12">
          <CNavbar light color="light" class="px-0">
                <h4>Student List</h4>
                <!-- <CForm inline>
                    <CInput class="mr-0 mb-0" placeholder="Search" size="sm" />
                    <CButton color="dark" size="sm" class="border-0">
                    <CIcon name="cil-search" size="sm" />
                    </CButton>
                </CForm> -->
                <CForm inline>
                   <b-input-group size="sm">
                      <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                      ></b-form-input>
                  </b-input-group>
                    <CButton color="dark" size="sm" class="border-0">
                    <CIcon name="cil-search" size="sm" />
                    </CButton>&nbsp;&nbsp;
                    <CButton
                    color="outline-dark "
                    class="my-2 my-sm-0 btn-out-dashed"
                    type="submit"
                    size="sm"
                    @click="next_page"
                    v-if="this.user != '8de886dd-12e5-4b02-b812-187639513779'"
                    >+ Add New</CButton
                    >
                </CForm>
            </CNavbar>
            <div class="table-wrapper py-3">
            <CRow>
              <CCol lg="12">
               <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="xs"
              show-empty
              small
              @filtered="onFiltered"
              >
                  <!-- <template #cell(actions)="row">
                      <b-button variant="success"  class="btn btn-xs btn-primary" size="sm" @click="info(row.item, row.index, $event.target)">
                      Edit
                      </b-button>
                  </template> -->
              </b-table>
              </CCol>
            </CRow>
          </div>
      </CCol>
      <!-- <CCol lg="4">
        <CloudStorageChart />
        <QuickNoteForm />
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";
import CTableWrapper from "../base/Tables.vue";
import usersData from "../users/UsersData";
import apis from "../../apis/index";

export default {
  name: "student_list",
  data(){
      return{
        items: [],
        user:"",
        filter:"",
        fields: [
            {
            key: "student_name",
            label: "Student Name",
            _classes: "font-weight-bold",
            },
            { key: "standard",label: "Class" },
            { key: "student_dob",label: "DOB" },
            { key: "parent_name",label: "Parent Name" },
            { key: "parent_contact_number",label: "Parent Contact No." },
            { key: "parent_email_id",label: "Parent Email id" }
        ],
      }
  },
  components: {
    WidgetStats,
    UserProfileCard,
    CloudStorageChart,
    QuickNoteForm,
    CTableWrapper
  },
  methods:{
    next_page(){
      this.$router.push('/dashboard/addstudent');
       //this.$router.push({ name: "AddStudent"});
    },
    get_student(){
      this.isLoading =true;
      var promise = apis.list_Students().then( response => {
        this.isLoading =false;
          this.items = response.data;
          this.totalRows = this.items.length;
        //  console.log(response);
        }).catch( error => {

        });
    },
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },

    getShuffledUsersData () {
      return this.shuffleArray(usersData.slice(0))
    }
  },
  created(){
    let user_id = this.$session.get('user_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    } 
    this.get_student();
    this.user = localStorage.getItem('user_role_id');
          
  }
};
</script>
